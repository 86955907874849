<template>
    <el-scrollbar class="education-wrapper" :native="false">
        <div class="steps-wrapper">
            <div class="step-item" :class="{current: [1, 2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建选品数据</div>
            </div>
            <div class="step-item" :class="{current: [2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">添加选品数据</div>
            </div>
            <div class="step-item" :class="{current: [3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 1">
            <el-form :model="stepOne" :rules="stepOneRules" ref="stepOne" label-width="120px">
                <el-form-item label="创建名称" prop="name">
                    <el-input v-model="stepOne.name" placeholder="4-30个字" clearable style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="行业分类" prop="industry_id">
                    <el-select v-model="stepOne.industry_id" placeholder="请选择行业分类" clearable style="width: 500px">
                        <el-option v-for="cateItem in categoryList" :key="cateItem.id" :label="cateItem.name" :value="cateItem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索人数" prop="search_num">
                    <el-input v-model.number="stepOne.search_num" placeholder="1000-15000" clearable style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="热门关键词" prop="key_word_id">
                    <el-select v-model="stepOne.key_word_id" placeholder="请选择关键词" clearable style="width: 500px">
                        <el-option v-for="hotItem in hotKeywordList" :key="hotItem.id" :label="hotItem.name" :value="hotItem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分值" prop="score">
                    <el-input v-model.number="stepOne.score" placeholder="0-100区间" clearable style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="金额" prop="amount">
                    <el-input v-model.number="stepOne.amount" placeholder="请输入金额" clearable style="width: 500px"></el-input>
                </el-form-item>
                <!-- <el-form-item label="商品信息" prop="goods_info_id">
                    <el-select v-model="stepOne.goods_info_id" placeholder="请选择商品信息" clearable style="width: 500px">
                        <el-option v-for="giItem in goodInfoList" :key="giItem.id" :label="giItem.name" :value="giItem.id"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="备注" prop="bz">
                    <el-input v-model="stepOne.bz" placeholder="2-255个字" style="width: 500px" maxlength="255" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="任务背景" prop="task_background">
                    <el-input v-model="stepOne.task_background" type="textarea" :rows="6" placeholder="1-1000个字" style="width: 500px" maxlength="1000" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="任务要求" prop="task_requirements">
                    <el-input v-model="stepOne.task_requirements" type="textarea" :rows="6" placeholder="1-1000个字" style="width: 500px" maxlength="1000" show-word-limit></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="create-content" v-if="stepActive === 2">
<!--            :rules="stepTwoRules"-->
            <el-form :model="stepOne" ref="stepOne" class="step-two-form">
                <el-form-item label="使用官方数据" label-width="108px" label-position="left" class="office-title">
                    <el-select v-model="templateId" placeholder="自定义" clearable style="width: 300px" @change="chooseSingleStoreSelection" @clear="clearSingle">
                        <el-option v-for="storeItem in storeSelectionList" :key="storeItem.id" :label="storeItem.name" :value="storeItem.id"></el-option>
                    </el-select>
                </el-form-item>
<!--                主图-->
                <div class="form-title">
                    <i class="iconfont">&#xe61c;</i>主图
                    <span class="uploader-prompt" style="margin-left: 16px;">建议尺寸：800 * 800；格式为.jpg 或 .jpeg 或 .png</span>
                </div>
                <div class="form-content">
                    <!-- <el-form-item prop="image" :rules="{required: true, message: '请上传图片', trigger: 'blur'}"> -->
                    <el-form-item prop="select_goods_data" :rules="{required: true, message: '请上传图片', trigger: 'blur'}">
                        <!-- <el-upload class="logo-uploader"
                                   :action="uploadCover"
                                   :headers="headersParams"
                                   name="file"
                                   :show-file-list="false"
                                   :on-success="handleCoverSuccess"
                                   :before-upload="beforeCoverUpload">
                            <img v-if="imgSrc" :src="imgSrc" class="logo-cover">
                            <i v-else class="el-icon-plus logo-uploader-icon"></i>
                        </el-upload> -->
                        <el-upload style="margin-bottom: 12px;"
                            :action="uploadCover"
                            :headers="headersParams"
                            name="file"
                            list-type="picture-card"
                            accept=".jpg, .jpeg, .png"
                            :on-success="handleImgSuccess">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <div v-for="(good, index) in stepOne.select_goods_data" style="display: flex; align-items: center; width: 800px; margin-bottom: 12px;">
                            <img :src="good.image" alt="" width="58px" height="58px" />
                            <span style="margin-left: 18px;">商品名称：</span>
                            <el-input v-model="good.name" placeholder="请输入商品名称" style="width: 200px"></el-input>
                            <span style="margin-left: 18px;">商品信息：</span>
                            <el-select v-model="good.goods_info_id" placeholder="请选择商品信息" style="width: 200px">
                                <el-option v-for="giItem in goodInfoList" :key="giItem.id" :label="giItem.name" :value="giItem.id"></el-option>
                            </el-select>
                            <el-link :underline="false" type="danger" style="margin-left: 18px;" @click="delSelectGood(index)">删除</el-link>
                        </div>
                    </el-form-item>
                </div>
<!--                子品牌数量-->
                <div class="form-title">
                    <i class="iconfont">&#xeaa8;</i>子品牌数量
                </div>
                <div class="form-content flex">
                    <el-form-item
                                  v-for="(brandItem, brandIndex) in stepOne.sub_brands_data"
                                  :label="brandItem.brandtype === 1 ? '一线品牌' : brandItem.brandtype === 2 ? '二线品牌' : '三线品牌'"
                                  :key="`sub_brands_data_${brandIndex}`"
                                  :prop="'sub_brands_data.' + brandIndex + '.subbrands'"
                                  :rules="{required: true, type: 'number', message: '须为1-26区间的数字', trigger: 'blur'}">
<!--                        <el-input v-model.number="brandItem.subbrands" placeholder="1-26" clearable style="width: 100px"></el-input>-->
                        <el-input-number v-model="brandItem.subbrands" :min="1" :max="26" :precision="0"
                                         :controls="false" style="width: 100px" placeholder="1-26"></el-input-number>
                    </el-form-item>
                </div>
<!--                品牌人数比例-->
                <div class="form-title">
                    <i class="iconfont">&#xe617;</i>品牌人数比例
                    <span class="form-title-prompt">(品牌人数比例总和是100%)</span>
                    <el-input size="medium" placeholder="总和" style="width: 100px; margin-left: 10px" disabled :value="totalScore"></el-input>
                    <span class="symbol-style" style="color: #666">%</span>
                </div>
                <div class="form-content flex">
                    <el-form-item
                            v-for="(peopleItem, peopleIndex) in stepOne.brands_num_data"
                            :label="peopleItem.brandtype === 1 ? '一线品牌' : peopleItem.brandtype === 2 ? '二线品牌' : '三线品牌'"
                            :key="`brands_num_data_${peopleIndex}`"
                            :prop="'brands_num_data.' + peopleIndex + '.brandproportion'"
                            :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                        <el-input v-model.number="peopleItem.brandproportion" clearable style="width: 100px"></el-input>-->
                        <el-input-number v-model="peopleItem.brandproportion" :min="0" :max="100" :precision="0"
                                         :ref="'brands_num_data' + peopleIndex + 'brandproportion'"
                                         :controls="false" style="width: 100px" placeholder=""></el-input-number>
                        <span class="symbol-style">%</span>
                    </el-form-item>
                </div>
<!--                商品信息-->
                <div class="form-title">
                    <i class="iconfont">&#xe619;</i>商品信息
                </div>
                <div class="form-content goods-info-wrapper">
                    <div class="item item-line1">
                        <div style="width: 70px; height: 16px; margin: 30px 10px 20px 0"></div>
                        <div class="line-title">成本价</div>
                        <div class="line-title mb40">波动范围</div>
                        <div class="line-title">市场价</div>
                        <div class="line-title mb40">波动范围</div>
                        <div class="line-title">点击率</div>
                        <div class="line-title mb40">波动范围</div>
                        <div class="line-title">转化率</div>
                        <div class="line-title">波动范围</div>
                    </div>
                    <div class="item item-line2" v-for="(goodItem, goodIndex) in stepOne.goods_data" :key="`goods_data_${goodIndex}`">
                        <div class="line-title line-title-first">
                            <span v-if="goodItem.brandtype === 1">一线品牌</span>
                            <span v-else-if="goodItem.brandtype === 2">二线品牌</span>
                            <span v-else-if="goodItem.brandtype === 3">三线品牌</span>
                        </div>
<!--                        成本价-->
                        <el-form-item class="line-item"
                                      :prop="'goods_data.' + goodIndex + '.costprice'"
                                      :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                            <el-input v-model.number="goodItem.costprice" placeholder="" clearable style="width: 160px"></el-input>-->
                            <el-input-number v-model="goodItem.costprice" :min="0.01" :precision="2" :ref="'goods_data' + goodIndex + 'costprice'"
                                             :controls="false" style="width: 160px" placeholder=""></el-input-number>
                        </el-form-item>
<!--                        成本价 波动范围-->
                        <div class="content-item flex mb18">
                            <el-form-item :prop="'goods_data.' + goodIndex + '.coslower'"
                                          :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                                <el-input v-model.number="goodItem.coslower" placeholder="" style="width: 60px"></el-input>-->
                                <el-input-number v-model="goodItem.coslower" :min="-99" :precision="0" :ref="'goods_data' + goodIndex + 'coslower'"
                                                 :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                <span class="symbol-style">%</span>
                            </el-form-item>
                            <span class="wavy-line">~</span>
                            <el-form-item :prop="'goods_data.' + goodIndex + '.cosupper'"
                                          :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                                <el-input v-model.number="goodItem.cosupper" placeholder="" style="width: 60px"></el-input>-->
                                <el-input-number v-model="goodItem.cosupper" :min="-98" :precision="0" :ref="'goods_data' + goodIndex + 'cosupper'"
                                                 :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                <span class="symbol-style">%</span>
                            </el-form-item>
                        </div>

<!--                        市场价-->
                        <el-form-item class="line-item"
                                      :prop="'goods_data.' + goodIndex + '.marketvalue'"
                                      :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                            <el-input v-model.number="goodItem.marketvalue" placeholder="" clearable style="width: 160px"></el-input>-->
                            <el-input-number v-model="goodItem.marketvalue" :min="0.01" :precision="2" :ref="'goods_data' + goodIndex + 'marketvalue'"
                                             :controls="false" style="width: 160px" placeholder=""></el-input-number>
                        </el-form-item>
<!--                        市场价波动范围-->
                        <div class="content-item flex mb18">
                            <el-form-item :prop="'goods_data.' + goodIndex + '.marketlower'"
                                          :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                                <el-input v-model.number="goodItem.marketlower" placeholder="" style="width: 60px"></el-input>-->
                                <el-input-number v-model="goodItem.marketlower" :min="-99" :precision="0" :ref="'goods_data' + goodIndex + 'marketlower'"
                                                 :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                <span class="symbol-style">%</span>
                            </el-form-item>
                            <span class="wavy-line">~</span>
                            <el-form-item :prop="'goods_data.' + goodIndex + '.marketupper'"
                                          :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                                <el-input v-model.number="goodItem.marketupper" placeholder="" style="width: 60px"></el-input>-->
                                <el-input-number v-model="goodItem.marketupper" :min="-98" :precision="0" :ref="'goods_data' + goodIndex + 'marketupper'"
                                                 :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                <span class="symbol-style">%</span>
                            </el-form-item>
                        </div>

<!--                        点击率-->
                        <el-form-item class="line-item"
                                      :prop="'goods_data.' + goodIndex + '.click'"
                                      :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                            <el-input v-model.number="goodItem.click" placeholder="" clearable style="width: 160px"></el-input>-->
                            <el-input-number v-model="goodItem.click" :min="0.01" :max="100" :precision="2" :ref="'goods_data' + goodIndex + 'click'"
                                             :controls="false" style="width: 160px" placeholder=""></el-input-number>
                            <span class="symbol-style">%</span>
                        </el-form-item>
<!--                        点击率波动范围-->
                        <div class="content-item flex mb18">
                            <el-form-item :prop="'goods_data.' + goodIndex + '.clilower'"
                                          :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                                <el-input v-model.number="goodItem.clilower" placeholder="" style="width: 60px"></el-input>-->
                                <el-input-number v-model="goodItem.clilower" :min="-999" :max="998" :precision="0" :ref="'goods_data' + goodIndex + 'clilower'"
                                                 :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                <span class="symbol-style">%</span>
                            </el-form-item>
                            <span class="wavy-line">~</span>
                            <el-form-item :prop="'goods_data.' + goodIndex + '.cliupper'"
                                          :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                                <el-input v-model.number="goodItem.cliupper" placeholder="" style="width: 60px"></el-input>-->
                                <el-input-number v-model="goodItem.cliupper" :min="-998" :max="1000" :precision="0" :ref="'goods_data' + goodIndex + 'cliupper'"
                                                 :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                <span class="symbol-style">%</span>
                            </el-form-item>
                        </div>

<!--                        转化率-->
                        <el-form-item class="line-item"
                                      :prop="'goods_data.' + goodIndex + '.conversion'"
                                      :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                            <el-input v-model.number="goodItem.conversion" placeholder="" clearable style="width: 160px"></el-input>-->
                            <el-input-number v-model="goodItem.conversion" :min="0.01" :max="100" :precision="2" :ref="'goods_data' + goodIndex + 'conversion'"
                                             :controls="false" style="width: 160px" placeholder=""></el-input-number>
                            <span class="symbol-style">%</span>
                        </el-form-item>
<!--                        转化率波动范围-->
                        <div class="content-item flex">
                            <el-form-item :prop="'goods_data.' + goodIndex + '.conlower'"
                                          :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                                <el-input v-model.number="goodItem.conlower" placeholder="" style="width: 60px"></el-input>-->
                                <el-input-number v-model="goodItem.conlower" :min="-999" :max="998" :precision="0" :ref="'goods_data' + goodIndex + 'conlower'"
                                                 :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                <span class="symbol-style">%</span>
                            </el-form-item>
                            <span class="wavy-line">~</span>
                            <el-form-item :prop="'goods_data.' + goodIndex + '.conupper'"
                                          :rules="{required: true, type: 'number', message: '必须是数字', trigger: 'blur'}">
<!--                                <el-input v-model.number="goodItem.conupper" placeholder="" style="width: 60px"></el-input>-->
                                <el-input-number v-model="goodItem.conupper" :min="-998" :max="1000" :precision="0" :ref="'goods_data' + goodIndex + 'conupper'"
                                                 :controls="false" style="width: 60px" placeholder=""></el-input-number>
                                <span class="symbol-style">%</span>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="create-content" v-if="stepActive === 3">
            <div class="success-content">
                <i class="iconfont">&#xe730;</i>
                <p class="text">创建成功！</p>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn(stepActive)" class="btn-blue-line-all" v-if="stepActive === 1 || stepActive === 2">上一步</el-button>
            <el-button @click="prevBtn(stepActive)" class="btn-blue-line-all" v-if="stepActive === 3">返回</el-button>
            <el-button class="btn-blue" @click="nextBtn(stepActive, 'stepOne')"  v-if="stepActive === 1">下一步</el-button>
            <el-button class="btn-blue" @click="nextBtn(stepActive, 'stepOne')"  v-if="stepActive === 2">下一步</el-button>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        data() {
            return {
                storeSelectionId: Number(this.$route.query.id) || '',
                stepActive: 1,
                stepOne: {
                    name: '',
                    industry_id: '',
                    search_num: null,
                    key_word_id: '',
                    score: null,
                    amount: null,
                    // goods_info_id: '',
                    bz: '',
                    task_background: '',
                    task_requirements: '',
                    sub_brands_data: [ // 子品牌数据
                        {
                            // title: '一线品牌',
                            subbrands: void 0,
                            brandtype: 1,
                        },
                        {
                            // title: '二线品牌',
                            subbrands: void 0,
                            brandtype: 2,
                        },
                        {
                            // title: '三线品牌',
                            subbrands: void 0,
                            brandtype: 3,
                        },
                    ],
                    brands_num_data: [ // 品牌人数比例
                        {
                            // title: '一线品牌',
                            brandproportion: void 0,
                            brandtype: 1,
                        },
                        {
                            // title: '二线品牌',
                            brandproportion: void 0,
                            brandtype: 2,
                        },
                        {
                            // title: '三线品牌',
                            brandproportion: void 0,
                            brandtype: 3,
                        },
                    ],
                    goods_data: [ // 商品信息数据
                        {
                            // title: '一线品牌',
                            brandtype: 1,
                            costprice: void 0,
                            coslower: void 0,
                            cosupper: void 0,
                            marketvalue: void 0,
                            marketlower: void 0,
                            marketupper: void 0,
                            click: void 0,
                            clilower: void 0,
                            cliupper: void 0,
                            conversion: void 0,
                            conlower: void 0,
                            conupper: void 0,
                        },
                        {
                            // title: '二线品牌',
                            brandtype: 2,
                            costprice: void 0,
                            coslower: void 0,
                            cosupper: void 0,
                            marketvalue: void 0,
                            marketlower: void 0,
                            marketupper: void 0,
                            click: void 0,
                            clilower: void 0,
                            cliupper: void 0,
                            conversion: void 0,
                            conlower: void 0,
                            conupper: void 0,
                        },
                        {
                            // title: '三线品牌',
                            brandtype: 3,
                            costprice: void 0,
                            coslower: void 0,
                            cosupper: void 0,
                            marketvalue: void 0,
                            marketlower: void 0,
                            marketupper: void 0,
                            click: void 0,
                            clilower: void 0,
                            cliupper: void 0,
                            conversion: void 0,
                            conlower: void 0,
                            conupper: void 0,
                        },
                    ],
                    // image: '',
                    select_goods_data: [],
                },
                templateId: null, // 官方数据id
                stepOneRules: {
                    name: [
                        { required: true, message: '请输入店铺选品名称', trigger: 'blur' },
                        { min: 4, max: 30, message: '长度在 4 到 30 个字', trigger: 'blur' }
                    ],
                    industry_id: [
                        { required: true, message: '请选择行业分类', trigger: 'change' }
                    ],
                    // goods_info_id: [
                    //     { required: true, message: '请选择商品信息', trigger: 'change' }
                    // ],
                    search_num: [
                        { required: true, message: '搜索人数必须为数字', trigger: 'blur' },
                        { min: 1000, max: 15000, type: 'number', message: '范围在1000-15000', trigger: 'blur'}
                    ],
                    key_word_id: [
                        { required: true, message: '请选择热门关键词', trigger: 'change' }
                    ],
                    score: [
                        { required: true, type: 'number', message: '分值必须为数字', trigger: 'blur'},
                        { min: 0, max: 100, type: 'number', message: '范围在0-100', trigger: 'blur'}
                    ],
                    amount: [
                        { required: true, message: '金额必须为数字, 且大于0', trigger: 'blur'},
                        { min: 0.01, type: 'number', message: '大于0', trigger: 'blur'}
                    ],
                    task_background: [
                        { required: true, message: '请输入任务背景', trigger: 'blur' },
                        { min: 1, max: 1000, message: '长度在 1 到 1000 个字', trigger: 'blur' }
                    ],
                    task_requirements: [
                        { required: true, message: '请输入任务要求', trigger: 'blur' },
                        { min: 1, max: 1000, message: '长度在 1 到 1000 个字', trigger: 'blur' }
                    ],
                },
                categoryList: [],
                goodInfoList: [],
                storeSelectionList: [],
                hotKeywordList: [],
                // S 上传图片需要
                uploadCover: this.$api.storeSelectionUploadHead,
                headersParams: {
                    Authorization: localStorage.getItem('token')
                },
                imgSrc: null,
                // E 上传图片需要
            }
        },
        mounted() {
            this.getCategoryList();
            this.getGoodInfoList();
            this.getHotKeywordsList();
            this.getStoreSelectionList()
            this.getSingleStoreSelection()
        },
        computed: {
            totalScore() {
                let total = 0
                if (Array.isArray(this.stepOne.brands_num_data)) {
                    this.stepOne.brands_num_data.forEach(item => {
                        if (isNaN(Number(item.brandproportion))) {
                            total += 0
                        } else {
                            total += Number(item.brandproportion)
                        }
                    })
                }
                return total
            }
        },
        methods: {
            delSelectGood(index){
                this.stepOne.select_goods_data.splice(index, 1);
            },
            // 获取所有店铺信息
            getStoreSelectionList() {
                this.$http.axiosGet(this.$api.storeSelection, (res) => {
                    if (res.code === 200) {
                        this.storeSelectionList = res.data.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取行业分类列表
            getCategoryList() {
                this.$http.axiosGet(this.$api.categoryList, (res) => {
                    if (res.code === 200) {
                        this.categoryList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取商品信息
            getGoodInfoList() {
                this.$http.axiosGet(this.$api.itemInfo, (res) => {
                    if (res.code === 200) {
                        this.goodInfoList = res.data.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取单条店铺选品信息
            getSingleStoreSelection() {
                if (this.storeSelectionId) {
                    this.$http.axiosGetBy(this.$api.storeSelection, {id: this.storeSelectionId}, (res) => {
                        if (res.code === 200) {
                            this.stepOne = res.data
                            this.stepOne.amount = Number(res.data.amount)
                            this.imgSrc = res.data.image
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            },
            // 获取热门关键词列表
            getHotKeywordsList() {
                this.$http.axiosGet(this.$api.hotKeywordList, (res) => {
                    if (res.code === 200) {
                        this.hotKeywordList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 选择单条数据
            chooseSingleStoreSelection(val) {
                if (val) {
                    this.$http.axiosGetBy(this.$api.storeSelection, {id: val}, (res) => {
                        if (res.code === 200) {
                            this.stepOne = {
                                name: this.stepOne.name,
                                industry_id: this.stepOne.industry_id,
                                // goods_info_id: this.stepOne.goods_info_id,
                                search_num: this.stepOne.search_num,
                                key_word_id: this.stepOne.key_word_id,
                                score: this.stepOne.score,
                                amount: this.stepOne.amount,
                                bz: this.stepOne.bz,
                                task_background: this.stepOne.task_background,
                                task_requirements: this.stepOne.task_requirements,
                                // image: res.data.image, // 记得看下返回的是不是这个地址
                                select_goods_data: res.data.select_goods_data,
                                sub_brands_data: res.data.sub_brands_data,
                                brands_num_data: res.data.brands_num_data,
                                goods_data: res.data.goods_data,
                            }
                            this.imgSrc = res.data.image
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            },
            // 清除使用官方数据
            clearSingle() {
                this.stepOne = {
                    name: this.stepOne.name,
                    industry_id: this.stepOne.industry_id,
                    // goods_info_id: this.stepOne.goods_info_id,
                    search_num: this.stepOne.search_num,
                    key_word_id: this.stepOne.key_word_id,
                    score: this.stepOne.score,
                    amount: this.stepOne.amount,
                    bz: this.stepOne.bz,
                    task_background: this.stepOne.task_background,
                    task_requirements: this.stepOne.task_requirements,
                    sub_brands_data: [ // 子品牌数据
                        {
                            // title: '一线品牌',
                            subbrands: void 0,
                            brandtype: 1,
                        },
                        {
                            // title: '二线品牌',
                            subbrands: void 0,
                            brandtype: 2,
                        },
                        {
                            // title: '三线品牌',
                            subbrands: void 0,
                            brandtype: 3,
                        },
                    ],
                    brands_num_data: [ // 品牌人数比例
                        {
                            // title: '一线品牌',
                            brandproportion: void 0,
                            brandtype: 1,
                        },
                        {
                            // title: '二线品牌',
                            brandproportion: void 0,
                            brandtype: 2,
                        },
                        {
                            // title: '三线品牌',
                            brandproportion: void 0,
                            brandtype: 3,
                        },
                    ],
                    goods_data: [ // 商品信息数据
                        {
                            // title: '一线品牌',
                            brandtype: 1,
                            costprice: void 0,
                            coslower: void 0,
                            cosupper: void 0,
                            marketvalue: void 0,
                            marketlower: void 0,
                            marketupper: void 0,
                            click: void 0,
                            clilower: void 0,
                            cliupper: void 0,
                            conversion: void 0,
                            conlower: void 0,
                            conupper: void 0,
                        },
                        {
                            // title: '二线品牌',
                            brandtype: 2,
                            costprice: void 0,
                            coslower: void 0,
                            cosupper: void 0,
                            marketvalue: void 0,
                            marketlower: void 0,
                            marketupper: void 0,
                            click: void 0,
                            clilower: void 0,
                            cliupper: void 0,
                            conversion: void 0,
                            conlower: void 0,
                            conupper: void 0,
                        },
                        {
                            // title: '三线品牌',
                            brandtype: 3,
                            costprice: void 0,
                            coslower: void 0,
                            cosupper: void 0,
                            marketvalue: void 0,
                            marketlower: void 0,
                            marketupper: void 0,
                            click: void 0,
                            clilower: void 0,
                            cliupper: void 0,
                            conversion: void 0,
                            conlower: void 0,
                            conupper: void 0,
                        },
                    ],
                    // image: '',
                    select_goods_data: [],
                }
                this.imgSrc = null
            },
            // 上一步按钮
            prevBtn(index) {
                if (index === 1) {
                    this.$router.go(-1)
                } else if (index === 2) {
                    this.stepActive = 1
                } else if (index === 3) {
                    this.$router.push('/admin/operatedata/storeselection')
                }
            },
            // 下一步按钮
            nextBtn(index, formName) {
                if(this.stepOne.industry_id == 0 || this.stepOne.industry_id == '') {
                    this.stepOne.category_id = ''
                    this.$message.error('请重新选择行业分类')
                    return
                }
                if(this.stepOne.key_word_id == 0 || this.stepOne.key_word_id == '') {
                    this.stepOne.key_word_id = ''
                    this.$message.error('请重新选择热门关键词')
                    return
                }
                // if(this.stepOne.goods_info_id == 0 || this.stepOne.goods_info_id == '') {
                //     this.stepOne.goods_info_id = ''
                //     this.$message.error('请重新选择商品信息')
                //     return
                // }
                // 第一步的下一步
                if (index === 1) {
                    if (this.stepOne.amount <= 0) {
                        return this.$message.warning('金额必须大于0')
                    }
                    if (Number(this.stepOne.search_num) < 1000 || Number(this.stepOne.search_num) > 15000) {
                        return this.$message.warning('搜索人数在1000-10000区间')
                    }
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let params = {
                                name: this.stepOne.name
                            }
                            if (this.storeSelectionId) {
                                params.id = this.storeSelectionId
                            }
                            this.$http.axiosGetBy(this.$api.storeSelectionName, params, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg)
                                    this.stepActive = 2
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                // 第二步的下一步
                } else if (index === 2) {
                    // if (this.stepOne.image === '') {
                    //     return this.$message.warning('请上传图片')
                    // }
                    let brandsData = this.stepOne.brands_num_data
                    brandsData.forEach((item, i) => {
                        if (this.totalScore > 100) {
                            this.$refs[`brands_num_data${i}brandproportion`][0].$el.classList.add('isError')
                        } else {
                            this.$refs[`brands_num_data${i}brandproportion`][0].$el.classList.remove('isError')
                        }
                    })
                    for (let i = 0; i < brandsData.length; i++) {
                        if (this.totalScore > 100) {
                            return this.$message.warning('品牌人数比例总和是100%')
                        }
                    }

                    let goodDataTmp = this.stepOne.goods_data
                    goodDataTmp.forEach((item, i) => {
                        // 成本价波动范围
                        if (Number(item.coslower) >= Number(item.cosupper)) {
                            this.$refs[`goods_data${i}cosupper`][0].$el.classList.add('isError')
                        } else {
                            this.$refs[`goods_data${i}cosupper`][0].$el.classList.remove('isError')
                        }
                        // 市场价波动范围
                        if (Number(item.marketlower) >= Number(item.marketupper)) {
                            this.$refs[`goods_data${i}marketupper`][0].$el.classList.add('isError')
                        } else {
                            this.$refs[`goods_data${i}marketupper`][0].$el.classList.remove('isError')
                        }
                        // 点击率波动范围
                        if (Number(item.clilower) >= Number(item.cliupper)) {
                            this.$refs[`goods_data${i}cliupper`][0].$el.classList.add('isError')
                        } else {
                            this.$refs[`goods_data${i}cliupper`][0].$el.classList.remove('isError')
                        }
                        // 转化率波动范围
                        if (Number(item.conlower) >= Number(item.conupper)) {
                            this.$refs[`goods_data${i}conupper`][0].$el.classList.add('isError')
                        } else {
                            this.$refs[`goods_data${i}conupper`][0].$el.classList.remove('isError')
                        }
                        // 市场价应高于成本价
                        if (Number(item.costprice) > Number(item.marketvalue)) {
                            this.$refs[`goods_data${i}marketvalue`][0].$el.classList.add('isError')
                        } else {
                            this.$refs[`goods_data${i}marketvalue`][0].$el.classList.remove('isError')
                        }
                    })
                    for (let i = 0; i < goodDataTmp.length; i++) {
                        // 成本价波动范围
                        if (Number(goodDataTmp[i].coslower) >= Number(goodDataTmp[i].cosupper)) {
                            return this.$message.warning('波动范围低值应小于高值')
                        }
                        // 市场价波动范围
                        if (Number(goodDataTmp[i].marketlower) >= Number(goodDataTmp[i].marketupper)) {
                            return this.$message.warning('波动范围低值应小于高值')
                        }
                        // 点击率波动范围
                        if (Number(goodDataTmp[i].clilower) >= Number(goodDataTmp[i].cliupper)) {
                            return this.$message.warning('波动范围低值应小于高值')
                        }
                        // 转化率波动范围
                        if (Number(goodDataTmp[i].conlower) >= Number(goodDataTmp[i].conupper)) {
                            return this.$message.warning('波动范围低值应小于高值')
                        }
                        // 市场价应高于成本价
                        if (Number(goodDataTmp[i].costprice) > Number(goodDataTmp[i].marketvalue)) {
                            return this.$message.warning('成本价应小于市场价')
                        }
                    }
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let formData = new FormData();
                            formData.append('name', this.stepOne.name);
                            formData.append('industry_id', this.stepOne.industry_id);
                            // formData.append('goods_info_id', this.stepOne.goods_info_id);
                            formData.append('search_num', this.stepOne.search_num);
                            formData.append('key_word_id', this.stepOne.key_word_id);
                            formData.append('score', this.stepOne.score);
                            formData.append('amount', this.stepOne.amount);
                            formData.append('bz', this.stepOne.bz);
                            formData.append('task_background', this.stepOne.task_background);
                            formData.append('task_requirements', this.stepOne.task_requirements);
                            // formData.append('image', this.stepOne.image);
                            formData.append('select_goods_data', JSON.stringify(this.stepOne.select_goods_data));
                            formData.append('sub_brands_data', JSON.stringify(this.stepOne.sub_brands_data));
                            formData.append('brands_num_data', JSON.stringify(this.stepOne.brands_num_data));
                            formData.append('goods_data', JSON.stringify(this.stepOne.goods_data));
                            if (this.storeSelectionId) {
                                formData.append('id', this.storeSelectionId)
                            }
                            this.$http.axiosPost(this.$api.storeSelection, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg)
                                    this.stepActive = 3
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
            },
            // start 上传图片
            beforeCoverUpload(file) {
                const isImg = file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png');
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isImg) {
                    this.$message.error('上传图片只能是 jpg 或 jpeg 或 png 格式!');
                    return false;
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                }
                return isImg && isLt2M;
            },
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                    this.imgSrc = res.data.src; // 有域名
                    this.stepOne.image = res.data.url; //无域名
                    this.$message.success('头像上传成功')
                } else {
                    this.$message.error('图片上传失败，请稍后再试~')
                }
            },
            // end 上传图片
            handleImgSuccess(res, file) {
                if(this.stepOne.select_goods_data.length >=6) return this.$message.warning('最多只能添加6个主图数据！');
                if (res.code === 200) {
                    this.stepOne.select_goods_data.push({ name: '', image: res.data.url, goods_info_id: '' });
                } else {
                    this.$message.error('图片上传失败，请稍后再试~')
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .el-breadcrumb {
        line-height: 40px;
    }
    .education-wrapper {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding: 30px;
            }
        }
    }
    .steps-wrapper {
        margin-top: 70px;
    }
    .create-content {
        margin: 60px 0;
    }
    .success-content {
        margin-top: 155px;
        text-align: center;
        .iconfont {
            color: #1FD18C;
            font-size: 52px;
        }
        .text {
            font-size: 24px;
            margin: 20px 0 0;
            font-weight: bold;
        }
    }
    .bottom-btn {
        text-align: center;
    }
    ::v-deep .step-two-form {
        padding-left: 30px;
        .office-title {
            margin-bottom: 40px;
            .el-form-item__label {
                font-size: 16px;
                font-weight: 500;
                color: #13131B;
            }
        }
        .form-title {
            margin-top: 20px;
            color: #1121D7;
            font-size: 16px;
            display: flex;
            align-items: center;
            line-height: 1;
            padding-bottom: 12px;
            position: relative;
            .iconfont {
                margin-right: 10px;
            }
            &:after {
                content: '';
                position: absolute;
                left: 26px;
                bottom: 0;
                width: 760px;
                border-bottom: 1px solid #DBDBDB;
            }
        }
        .form-content {
            padding-left: 45px;
            & > .el-form-item {
                margin-top: 20px;
                margin-right: 110px;
                width: 120px;
            }
            &.goods-info-wrapper {
                display: flex;
                .item {
                    .line-title {
                        line-height: 41px;
                        width: 70px;
                        margin-bottom: 22px;
                        text-align: right;
                        position: relative;
                        margin-right: 10px;
                        &.line-title-first {
                            width: 160px;
                            line-height: 1;
                            font-size: 16px;
                            color: #13131B;
                            height: 16px;
                            text-align: center;
                            margin: 30px 60px 20px 0;
                        }
                    }
                    &.item-line1 {
                        .line-title {
                            &:before {
                                content: '*';
                                color: #F56C6C;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
        }
        .symbol-style {
            margin-left: 6px;
        }
        .wavy-line {
            line-height: 40px;
            margin: 0 7px 0 6px;
        }
        .mb40 {
            margin-bottom: 40px!important;
        }
        .mb18 {
            margin-bottom: 18px!important;
        }
    }
    .logo-uploader {
        width: 200px;
        height: 200px;
        border: 1px solid #ddd;
        background: #DFDFE5;
        ::v-deep .el-upload {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .logo-cover {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
        .logo-uploader-icon {
            color: #999;
            font-size: 24px;
        }
    }
    .uploader-prompt {
        color: #F56C6C;
        white-space: nowrap;
    }
    .form-title-prompt {
        font-size: 14px;
        color: #999;
        margin-left: 10px;
    }
    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
        &.isError {
            .el-input__inner {
                border-color: #FD4446;
            }
        }
    }
</style>
<style>
.el-upload-list--picture-card {
    display: none;
}
.el-upload--picture-card {
    width: 58px;
    height: 58px;
}
.el-upload--picture-card i {
    position: absolute;
    margin-top: 14px;
    margin-left: -14px;
}
</style>